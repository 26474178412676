import { PageProps, graphql } from 'gatsby';
import {
  DatoCmsComponentHeading,
  DatoCmsSeoField,
  DatoCmsTemplateCaseStudyConnection,
  DatoCmsTemplatePage,
} from 'graphqlTypes';

import Section from 'molecules/Section/Section';

import CaseStudyHero from 'components/CaseStudy/CaseStudyHero';
import CaseStudyListings from 'components/CaseStudy/CaseStudyListings';

import Layout from 'layouts/Layout';
import LayoutSection from 'layouts/LayoutSection';

import type { FC } from 'react';

interface PageDataType {
  pageData: DatoCmsTemplatePage;
  caseStudies: DatoCmsTemplateCaseStudyConnection;
  caseStudyHeading: DatoCmsComponentHeading;
}

const Page: FC<PageProps<PageDataType>> = ({
  data: {
    pageData: { seo, sections },
    caseStudies,
    caseStudyHeading,
  },
  location,
  pageContext,
}) => (
  <Layout slug={location.pathname} seo={seo as DatoCmsSeoField} domain={(pageContext as any)?.domain}>
    <Section
      id="case-study-hero-section"
      isOverSection={true}
      hasPrefix={false}
      hasSubfix={true}
      isLastSection={false}
      backgroundColor="secondaryGrayBlue-900"
    >
      <CaseStudyHero heading={caseStudyHeading} caseStudies={caseStudies?.nodes?.slice(0, 2)} />
    </Section>
    <Section
      id="case-study-listing-section"
      isOverSection={true}
      hasPrefix={false}
      hasSubfix={false}
      isLastSection={false}
      backgroundColor="secondaryGrayBlue-25"
    >
      <CaseStudyListings caseStudies={caseStudies?.nodes} />
    </Section>
    {sections &&
      sections.map(
        (section, index) =>
          section && <LayoutSection key={section.id} isLastSection={index === sections.length - 1} {...section} />,
      )}
  </Layout>
);
export default Page;

export const caseStudyListingPageQuery = graphql`
  query templateCaseStudyListingQuery($slug: String!, $domain: String!) {
    pageData: datoCmsTemplatePage(slug: { eq: $slug }, domain: { eq: $domain }) {
      ...datoCmsTemplatePage
    }
    caseStudies: allDatoCmsTemplateCaseStudy(
      sort: { fields: publishDate, order: DESC }
      filter: { domain: { eq: $domain } }
    ) {
      nodes {
        ...datoCmsTemplateCaseStudyList
      }
    }
    caseStudyHeading: datoCmsComponentHeading(domain: { eq: $domain }) {
      ...datoCmsComponentHeading
    }
  }
`;
